import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { silentRequest } from '../../../authConfig';

import { useMsal, useAccount } from '@azure/msal-react';

import { useTranslation } from 'react-i18next';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Icon,
  Calendar,
  DateRangeType,
  TextField,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  SearchBox,
  PrimaryButton,
  DefaultButton,
  ContextualMenu,
  CheckboxVisibility,
  Text,
  Dialog,
  DialogType,
  DialogFooter,
  DatePicker,
  Checkbox,
  DetailsRow,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

const client_id = process.env.REACT_APP_XERO_CLIENT_ID;
const redirect_uri = PUBLIC_URL + '/etax/sources/xero/connect';

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const checkItems = (items) => {
  return ((Object.keys(items[0]).length === 1 && Object.keys(items[0]).includes("status")) || Object.keys(items[0]).length === 0)
}

function Xero() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t, i18n } = useTranslation();

  const [company, setCompany] = useState('');
  const [items, setItems] = useState([]);
  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  const nonVatText = '-';

  const [includeVat, setIncludeVat] = useState(true);

  const [registerVat, setRegisterVat] = useState(true);

  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);
  const pageSize = 100;
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('xero-currentPage') || 1);

  const [createdBy, setCreatedBy] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [tenantId_array, setTenantId_array] = useState([]);

  const [tenant, setTenant] = useState({ key: '', text: t('xero.loading') });

  const companyId = sessionStorage.getItem('companyId') || '';

  let _pageDropdown = [];

  for (let i = 1; i <= 50; i++) {
    _pageDropdown.push({
      key: 'page-' + i,
      text: t('xero.page') + ' ' + i,
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('Selected page: ' + i);
        setCurrentPage(i);
      },
    });
  }

  const pageDropdown = _pageDropdown;

  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    (sessionStorage.getItem('xero-documentType-key') && {
      key: sessionStorage.getItem('xero-documentType-key'),
      text: sessionStorage.getItem('xero-documentType-text'),
    }) || {
    key: 'all',
    text: t('xero.allDocuments'),
  });

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('xero-primarySort') || 'documentDate');
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('xero-sort-documentDate') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('xero-sort-number')) {
      if (sessionStorage.getItem('xero-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('xero-sort-customerName')) {
      if (sessionStorage.getItem('xero-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('xero-sort-vatTotal')) {
      if (sessionStorage.getItem('xero-sort-vatTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('xero-sort-grandTotal')) {
      if (sessionStorage.getItem('xero-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);  

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;

  const [search, setSearch] = useState('');

  const DayPickerStrings = {
    months: [
      t('xero.months01'),
      t('xero.months02'),
      t('xero.months03'),
      t('xero.months04'),
      t('xero.months05'),
      t('xero.months06'),
      t('xero.months07'),
      t('xero.months08'),
      t('xero.months09'),
      t('xero.months10'),
      t('xero.months11'),
      t('xero.months12'),
    ],

    shortMonths: [
      t('xero.shortMonths01'),
      t('xero.shortMonths02'),
      t('xero.shortMonths03'),
      t('xero.shortMonths04'),
      t('xero.shortMonths05'),
      t('xero.shortMonths06'),
      t('xero.shortMonths07'),
      t('xero.shortMonths08'),
      t('xero.shortMonths09'),
      t('xero.shortMonths10'),
      t('xero.shortMonths11'),
      t('xero.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('xero.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('xero.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const createDocument = async (documentType, item) => {
    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoice-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      default:
        break;
    }

    let _date = new Date(item.createdTime);

    let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
    let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

    let _dueDate = '';
    let _dueDateBE = '';
    let _dueDateCE = '';

    if (item.dueDateTime) {
      _dueDate = new Date(item.dueDateTime);

      _dueDateBE =
        `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_dueDate.getFullYear() + 543);

      _dueDateCE = `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();
    }

    let _items = [];
    let _discount = 0;

    let phoneNumber = item.customerPhone;

    if (phoneRegEx.test(phoneNumber) !== true) {
      phoneNumber = '';
    }

    let _customer = {
      addressLineOne: item.customerAddress,
      addressLineTwo: '',
      email: item.customerEmail,
      name: item.customerName,
      phone: phoneNumber,
      phoneCountryCode: '+66',
      postcode: item.customerPostcode,
      taxId: item.customerTaxId,
      taxNumberType: item.taxNumberType,
      branchText: item.branchText,
      branchNumber: item.branchNumber,
      language: 'en',
      unstructure: true,
    };

    if (item.items !== undefined && item.items[0] !== undefined) {
      item.items.forEach((item, index) => {
        if (Number(item.UnitAmount) < 0) {
          _discount += Number(item.UnitAmount) * -1;
        } else {
          let _percentVat;
          let _percentVatText;

          if (item.TaxAmount > 0) {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          } else {
            if (item.TaxType === 'NONE') {
              _percentVat = nonVat;
              _percentVatText = nonVatText;
            } else {
              _percentVat = zeroVat;
              _percentVatText = zeroVatText;
            }
          }

          let item_obj = {
            number: index + 1 + '',
            description: item.Description.replaceAll('\r\n', ''),
            quantity: Number(item.Quantity),
            unitCode: '',
            unitName: '-',
            price: Number(item.UnitAmount),
            discount: item.DiscountAmount ? item.DiscountAmount : 0,
            total: Number(item.LineAmount),
            percentVat: _percentVat,
            percentVatText: _percentVatText,
          };

          _items.push(item_obj);
        }
      });
    }

    console.log('_items: ', _items);
    console.log('_customer: ', _customer);
    console.log(_date);
    console.log(_dateBE);
    console.log(_dateCE);

    history.push({
      pathname: documentPath,
      state: {
        command: 'EDIT',
        amountTotal: item.amountTotal,
        company: {
          ...company,
          includeVat: includeVat,
        },
        customer: _customer,
        date: _date,
        dateBE: _dateBE,
        dateCE: _dateCE,
        dueDate: _dueDate,
        dueDateBE: _dueDateBE,
        dueDateCE: _dueDateCE,
        grandTotal: item.grandTotal,
        items: _items,
        note: item.note,
        number: item.number,
        // reference: item.number,
        percentVat: percentVat,
        receivedBy: '',
        vatTotal: item.vatTotal,
        reIssue: false,
        discount: _discount,
        backUrl: '/etax/sources/xero',
        includeVat: item.includeVat,
      },
    });
  };

  const createSelectedDocuments = async (documentType) => {
    let _items = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _items.length; j++) {
              if (_items[j].key === selectedItems[i].key) {
                console.log('item key: ', _items[j].key);

                _items[j].status = 'processing';

                console.log('items: ', _items);

                setItems([..._items]);

                console.log('item: ', _items[j]);

                let _date = new Date(selectedItems[i].createdTime);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let _dueDate = '';
                let _dueDateBE = '';
                let _dueDateCE = '';

                if (selectedItems[i].dueDateTime) {
                  _dueDate = new Date(selectedItems[i].dueDateTime);

                  _dueDateBE =
                    `${_dueDate.getDate()}`.padStart(2, '0') +
                    '/' +
                    `${_dueDate.getMonth() + 1}`.padStart(2, '0') +
                    '/' +
                    (_dueDate.getFullYear() + 543);

                  _dueDateCE =
                    `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();
                }

                let _itemsInvoice = [];
                let _discount = 0;

                let phoneNumber = selectedItems[i].customerPhone;

                if (phoneRegEx.test(phoneNumber) !== true) {
                  phoneNumber = '';
                }

                let _customer = {
                  addressLineOne: selectedItems[i].customerAddress,
                  addressLineTwo: '',
                  email: selectedItems[i].customerEmail,
                  name: selectedItems[i].customerName,
                  phone: phoneNumber,
                  phoneCountryCode: '+66',
                  postcode: selectedItems[i].customerPostcode,
                  taxId: selectedItems[i].customerTaxId,
                  taxNumberType: selectedItems[i].taxNumberType,
                  branchText: selectedItems[i].branchText,
                  branchNumber: selectedItems[i].branchNumber,
                  language: 'th',
                  unstructure: true,
                };

                if (selectedItems[i].items !== undefined && selectedItems[i].items[0] !== undefined) {
                  selectedItems[i].items.forEach((item, index) => {
                    if (Number(item.UnitAmount) < 0) {
                      _discount += Number(item.UnitAmount) * -1;
                    } else {
                      let _percentVat;
                      let _percentVatText;

                      if (item.TaxAmount > 0) {
                        _percentVat = percentVat;
                        _percentVatText = percentVatText;
                      } else {
                        if (item.TaxType === 'NONE') {
                          _percentVat = nonVat;
                          _percentVatText = nonVatText;
                        } else {
                          _percentVat = zeroVat;
                          _percentVatText = zeroVatText;
                        }
                      }

                      let item_obj = {
                        number: index + 1 + '',
                        description: item.Description.replaceAll('\r\n', ''),
                        quantity: Number(item.Quantity),
                        unitCode: '',
                        unitName: '-',
                        price: Number(item.UnitAmount),
                        discount: item.DiscountAmount ? item.DiscountAmount : 0,
                        total: Number(item.LineAmount),
                        percentVat: _percentVat,
                        percentVatText: _percentVatText,
                      };

                      _itemsInvoice.push(item_obj);
                    }
                  });
                }

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _itemsInvoice.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  } else if (item.percentVat === nonVat) {
                    _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                  }
                });

                console.log('_items: ', selectedItems[i].items);
                console.log('_customer: ', _customer);
                console.log(_date);
                console.log(_dateBE);
                console.log(_dateCE);

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        amountTotal: selectedItems[i].amountTotal,
                        company: {
                          ...company,
                          includeVat: includeVat,
                        },
                        // reference: selectedItems[i].number,
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        dueDate: _dueDate,
                        dueDateBE: _dueDateBE,
                        dueDateCE: _dueDateCE,
                        discount: _discount,
                        grandTotal: selectedItems[i].grandTotal,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        items: _itemsInvoice,
                        percentVat: registerVat ? percentVat : 0,
                        receivedBy: createdBy,
                        createdBy: createdBy,
                        vatTotal: selectedItems[i].vatTotal,
                        reIssue: false,
                        includeVat: selectedItems[i].includeVat,
                        number: selectedItems[i].number,
                        note: selectedItems[i].note,
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                                'cid': companyId
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _items[j].status = 'successful';
                                _items[j].etaxCreated = true;

                                setItems([..._items]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count >= 300) {
                                _items[j].status = 'fail';

                                setItems([..._items]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _items[j].status = 'fail';
                        setItems([..._items]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  const handleSort = (primary, isSorted) => {
    if (items.length > 0) {
      let _items = selection.getItems();
      let _selectedItems = selection.getSelection();
      console.log('selection: ', selection.getSelection());

      for (let i = 0; i < _selectedItems.length; i++) {
        console.log('select item key: ', _selectedItems[i].key);
        _selectedItems[i].status = '';

        for (let j = 0; j < _items.length; j++) {
          if (_items[j].key === _selectedItems[i].key) {
            console.log('item key: ', _items[j].key);
            _items[j].status = '';
            _selectedItems[i].status = '';
          }
        }
      }

      selection.setAllSelected(false);

      if (primary === 'documentDate') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (a.createdTime < b.createdTime) {
              return -1;
            }
            if (a.createdTime > b.createdTime) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        } else {
          _items.sort((a, b) => {
            if (b.createdTime < a.createdTime) {
              return -1;
            }
            if (b.createdTime > a.createdTime) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        }
      } else if (primary === 'number') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (b.number < a.number) {
              return -1;
            }
            if (b.number > a.number) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        } else {
          _items.sort((a, b) => {
            if (a.number < b.number) {
              return -1;
            }
            if (a.number > b.number) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        }
      } else if (primary === 'customerName') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (b.customerName < a.customerName) {
              return -1;
            }
            if (b.customerName > a.customerName) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        } else {
          _items.sort((a, b) => {
            if (a.customerName < b.customerName) {
              return -1;
            }
            if (a.customerName > b.customerName) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        }
      } else if (primary === 'vatTotal') {
        if (isSorted) {
          _items.sort(function (a, b) {
            return b.vatTotal - a.vatTotal;
          });

          setItems(_items);
        } else {
          _items.sort(function (a, b) {
            return a.vatTotal - b.vatTotal;
          });

          setItems(_items);
        }
      } else if (primary === 'grandTotal') {
        if (isSorted) {
          _items.sort(function (a, b) {
            return b.grandTotal - a.grandTotal;
          });

          setItems(_items);
        } else {
          _items.sort(function (a, b) {
            return a.grandTotal - b.grandTotal;
          });

          setItems(_items);
        }
      }
    }
  };

  const columns = [
    {
      key: 'column1',
      name: t('xero.documentDate'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: !isSortedDescendingDocumentDate,
      isSortedAscending: isSortedDescendingDocumentDate,
      onColumnClick: (item) => {
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        handleSort('documentDate', !isSortedDescendingDocumentDate);

        sessionStorage.setItem('xero-primarySort', 'documentDate');
        sessionStorage.setItem('xero-sort-documentDate', !isSortedDescendingDocumentDate === false ? 'false' : 'true');

        sessionStorage.removeItem('xero-sort-number');
        sessionStorage.removeItem('xero-sort-customerName');
        sessionStorage.removeItem('xero-sort-vatTotal');
        sessionStorage.removeItem('xero-sort-grandTotal');
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.createdTime ? item.createdTime.split('T')[0] : ''}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column2',
      name: t('xero.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        handleSort('number', !isSortedDescendingNumber);

        sessionStorage.setItem('xero-primarySort', 'number');
        sessionStorage.setItem('xero-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('xero-sort-documentDate');
        sessionStorage.removeItem('xero-sort-customerName');
        sessionStorage.removeItem('xero-sort-vatTotal');
        sessionStorage.removeItem('xero-sort-grandTotal');
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column3',
      name: t('xero.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 135,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        handleSort('customerName', !isSortedDescendingCustomerName);

        sessionStorage.setItem('xero-primarySort', 'customerName');
        sessionStorage.setItem('xero-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('xero-sort-documentDate');
        sessionStorage.removeItem('xero-sort-number');
        sessionStorage.removeItem('xero-sort-vatTotal');
        sessionStorage.removeItem('xero-sort-grandTotal');
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column4',
      name: t('xero.customerAddress'),
      fieldName: 'customerAddress',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column5',
      name: t('xero.phone'),
      fieldName: 'phone',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerPhone}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column6',
      name: 'VAT',
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort document vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        handleSort('vatTotal', !isSortedDescendingVatTotal);

        sessionStorage.setItem('xero-primarySort', 'vatTotal');
        sessionStorage.setItem('xero-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('xero-sort-documentDate');
        sessionStorage.removeItem('xero-sort-number');
        sessionStorage.removeItem('xero-sort-customerName');
        sessionStorage.removeItem('xero-sort-grandTotal');
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal ? item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) : ''}
          </span>
        );
      },
      // isPadded: true,
    },
    {
      key: 'column7',
      name: t('xero.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort document grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        handleSort('grandTotal', !isSortedDescendingGrandTotal);

        sessionStorage.setItem('xero-primarySort', 'grandTotal');
        sessionStorage.setItem('xero-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('xero-sort-documentDate');
        sessionStorage.removeItem('xero-sort-customerName');
        sessionStorage.removeItem('xero-sort-number');
        sessionStorage.removeItem('xero-sort-vatTotal');
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal ? item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) : ''}
          </span>
        );
      },
      // isPadded: true,
    },
    {
      key: 'column8',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'create',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('xero.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('xero.processing')} labelPosition="right" style={{ marginLeft: '-35px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('xero.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('xero.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('xero.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('xero.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('xero.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('xero.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('xero.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('xero.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('xero.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const getMonth = (goBack) => {
    let monthNames = [
      t('xero.months01'),
      t('xero.months02'),
      t('xero.months03'),
      t('xero.months04'),
      t('xero.months05'),
      t('xero.months06'),
      t('xero.months07'),
      t('xero.months08'),
      t('xero.months09'),
      t('xero.months10'),
      t('xero.months11'),
      t('xero.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('xero-queryMonth-key') && {
      key: sessionStorage.getItem('xero-queryMonth-key'),
      text: sessionStorage.getItem('xero-queryMonth-text'),
      ...(sessionStorage.getItem("xero-queryMonth-type") ? { type: sessionStorage.getItem("xero-queryMonth-type") } : {}),
    }) || { key: getMonth(0).key, text: t('xero.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("xero-queryMonth-type") ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("xero-fromDate") ? new Date(sessionStorage.getItem("xero-fromDate")) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem("xero-toDate") ? new Date(sessionStorage.getItem("xero-toDate")) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const [date, setDate] = useState(sessionStorage.getItem('xero-date') ? new Date(sessionStorage.getItem('xero-date')) : new Date());

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const getFrom2 = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    return formatDate2(_firstDay);
  };

  const getTo2 = (month, year) => {
    const _totalDays = new Date(year, month + 1, 0).getDate();
    let _lastDay = new Date(year, month, _totalDays);

    return formatDate2(_lastDay);
  };

  const getFrom2DMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return formatDate2(_firstDay);
  };

  const getTo2DMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return formatDate2(_lastDay);
  };

  const formatDate2 = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join(',');
  };

  const [from2, setFrom2] = useState(sessionStorage.getItem('xero-from2') || getFrom2(getMonth(0).month, getMonth(0).year));
  const [to2, setTo2] = useState(sessionStorage.getItem('xero-to2') || getTo2(getMonth(0).month, getMonth(0).year));

  const history = useHistory();

  function getTenant_items() {
    let array = [];

    if (tenantId_array !== undefined) {
      tenantId_array.forEach((tenant_obj, index) => {
        array.push({
          key: index + 1,
          text: tenant_obj.tenantName,
          iconProps: { iconName: 'CityNext2' },
          onClick: () => {
            setTenant({
              key: tenant_obj.tenantId,
              text: tenant_obj.tenantName,
            });

            sessionStorage.setItem('xero-tenant-key', tenant_obj.tenantId);
            sessionStorage.setItem('xero-tenant-text', tenant_obj.tenantName);

            setLoadDataComplete(false);
            setItems([]);
            setGetNewDocuments(true);

            setSearch('');

            setCurrentPage(1);
          },
        });
      });

      array.push({
        key: array.length + 1,
        text: t('xero.anotherOrganization'),
        iconProps: { iconName: 'PlugConnected' },
        href: `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid profile email accounting.transactions accounting.contacts offline_access`,
      });
    }

    return array;
  }

  function getDocumentTypeText(key) {
    if (key === 'all') return t('xero.allDocuments');
    if (key === 'AUTHORISED') return t('xero.authorised');
    if (key === 'PAID') return t('xero.paid');
    if (key === 'DRAFT') return t('xero.draft');
    if (key === 'VOIDED') return t('xero.voided');
    if (key === 'DELETED') return t('xero.deleted');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('xero.thisMonth');

    let monthNames = [
      t('xero.months01'),
      t('xero.months02'),
      t('xero.months03'),
      t('xero.months04'),
      t('xero.months05'),
      t('xero.months06'),
      t('xero.months07'),
      t('xero.months08'),
      t('xero.months09'),
      t('xero.months10'),
      t('xero.months11'),
      t('xero.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: tenant.key,
      text: tenant.text,
      disabled: !loadDataComplete || isCreateDocument || search,
      iconProps: { iconName: 'CityNext2' },
      subMenuProps: {
        items: getTenant_items(),
      },
    },

    {
      key: documentType.key,
      text: getDocumentTypeText(documentType.key),
      disabled: !loadDataComplete || isCreateDocument || search,
      iconProps: { iconName: 'Page' },
      subMenuProps: {
        items: [
          {
            key: 'all',
            text: t('xero.allDocuments'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('xero.allDocuments'));
              setDocumentType({ key: 'all', text: t('xero.allDocuments') });

              sessionStorage.setItem('xero-documentType-key', 'all');
              sessionStorage.setItem('xero-documentType-text', t('xero.allDocuments'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'AUTHORISED',
            text: t('xero.authorised'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('xero.authorised'));
              setDocumentType({ key: 'AUTHORISED', text: t('xero.authorised') });

              sessionStorage.setItem('xero-documentType-key', 'AUTHORISED');
              sessionStorage.setItem('xero-documentType-text', t('xero.authorised'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'PAID',
            text: t('xero.paid'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('xero.paid'));
              setDocumentType({ key: 'PAID', text: t('xero.paid') });

              sessionStorage.setItem('xero-documentType-key', 'PAID');
              sessionStorage.setItem('xero-documentType-text', t('xero.paid'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'DRAFT',
            text: t('xero.draft'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('xero.draft'));
              setDocumentType({ key: 'DRAFT', text: t('xero.draft') });

              sessionStorage.setItem('xero-documentType-key', 'DRAFT');
              sessionStorage.setItem('xero-documentType-text', t('xero.draft'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'VOIDED',
            text: t('xero.voided'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('xero.voided'));
              setDocumentType({ key: 'VOIDED', text: t('xero.voided') });

              sessionStorage.setItem('xero-documentType-key', 'VOIDED');
              sessionStorage.setItem('xero-documentType-text', t('xero.voided'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
          {
            key: 'DELETED',
            text: t('xero.deleted'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('xero.deleted'));
              setDocumentType({ key: 'DELETED', text: t('xero.deleted') });

              sessionStorage.setItem('xero-documentType-key', 'DELETED');
              sessionStorage.setItem('xero-documentType-text', t('xero.deleted'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('sellSummary.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.fromRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={fromDate}
                        maxDate={toDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setFromDate(_date);

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.toRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={toDate}
                        minDate={fromDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setToDate(_date);
                          
                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {timeRangeError ? (
                    <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                      <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('sellSummary.timeRangeError')}</Text>
                    </Stack>
                  ) : null}
                  <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                    <PrimaryButton
                      text={t('sellSummary.search')}
                      onClick={() => {
                        if (!timeRangeError && fromDate && toDate) {
                          setQueryMonth({
                            type: 'dateRange',
                            key: formatDate(fromDate) + ':' + formatDate(toDate),
                            text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                          });

                          sessionStorage.setItem("xero-queryMonth-type", "dateRange");
                          sessionStorage.setItem("xero-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                          sessionStorage.setItem("xero-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                          setFrom2(getFrom2DMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          setTo2(getTo2DMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem('xero-from2', getFrom2DMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem('xero-to2', getTo2DMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem("xero-fromDate", fromDate.toISOString());
                          sessionStorage.setItem("xero-toDate", toDate.toISOString());

                          setLoadDataComplete(false);
                          setItems([]);
                          setGetNewDocuments(true);

                          setCurrentPage(1);

                          setSearch('');
                        }
                      }}
                      style={{ width: '125px', letterSpacing: '1px' }}
                    />
                  </Stack>
                </Stack>
              ) : (
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('xero.months01'),
                    t('xero.months02'),
                    t('xero.months03'),
                    t('xero.months04'),
                    t('xero.months05'),
                    t('xero.months06'),
                    t('xero.months07'),
                    t('xero.months08'),
                    t('xero.months09'),
                    t('xero.months10'),
                    t('xero.months11'),
                    t('xero.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('xero-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem("xero-queryMonth-type", "");
                  sessionStorage.setItem('xero-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('xero-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom2(getFrom2(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo2(getTo2(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('xero-from2', getFrom2(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('xero-to2', getTo2(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setSearch('');
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
              )}
            </div>
          );
        },
      },
    },
    {
      key: 'page-' + currentPage,
      text: t('xero.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('xero.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setCurrentPage(1);

        setSearch('');
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('xero.disconnect'),
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Xero disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('xero.disconnect'),
          message: t('xero.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'xero',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then((response) => {
              console.log('disconnect: ', response);

              history.push({
                pathname: '/etax/sources/xero/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  setCompany(_company);
  
                  if (_company.registerVat) {
                    setIncludeVat(true);
                    setRegisterVat(true);
                  } else {
                    setIncludeVat(false);
                    setRegisterVat(false);
                  }
                } else {
                  setCompany(response.data[0]);
  
                  if (response.data[0].registerVat) {
                    setIncludeVat(true);
                    setRegisterVat(true);
                  } else {
                    setIncludeVat(false);
                    setRegisterVat(false);
                  }

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/sources/xero/tenants', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            function (response) {
              console.log('Tenants: ', response);
              if (response.data.tenantId_array && response.data.tenantId_array.length > 0) {
                setTenantId_array(response.data.tenantId_array);

                setTenant({
                  key: response.data.tenantId_array[0].tenantId,
                  text: response.data.tenantId_array[0].tenantName,
                });

                setGetNewDocuments(true);
              } else {
                disconnect(true);
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
              disconnect(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function merge(invoices, contacts) {
    let newInvoices = [];
    invoices.forEach((invoice) => {
      contacts.forEach((contact) => {
        if (invoice.Contact.ContactID === contact.ContactID) {
          invoice.Contact = contact;
          newInvoices.push(invoice);
        }
      });
    });

    return newInvoices;
  }

  useEffect(() => {
    console.log('getNewOrder Running!!!');

    console.log('tenant: ', tenant);
    console.log('getNewDocuments: ', getNewDocuments);

    if (getNewDocuments && tenant.key) {
      if (search?.trim()) {
        const searchInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              await axios
                .get(API_URL + '/sources/xero/invoices', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId
                  },
                  params: {
                    tenantId: tenant.key,
                    search: search.trim(),
                  },
                })
                .then(async (response) => {
                  console.log("response search", response)
                  if (response.data.invoice.length) {
                    const _number = response.data.invoice[0].InvoiceNumber;

                    await axios
                      .get(API_URL + `/documents?numberList=${_number}`, {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'Content-Type': 'application/json',
                          'cid': companyId
                        },
                      })
                      .then((responseAllDocs) => {
                        let invoices = merge(response.data.invoice.filter(_invoice => _invoice.DateString), response.data.contact);
                        console.log("invoices", invoices)

                        invoices.sort((a, b) => new Date(a.DateString).getTime() - new Date(b.DateString).getTime());
                        invoices.reverse();

                        if (primarySort === 'documentDate') {
                          if (isSortedDescendingDocumentDate) {
                            invoices.sort(function (a, b) {
                              var aa = a.DateString.split('-').reverse().join('-').split('-').reverse().join(),
                                bb = b.DateString.split('-').reverse().join('-').split('-').reverse().join();
                              return aa < bb ? -1 : aa > bb ? 1 : 0;
                            });
                          } else {
                            invoices.sort(function (a, b) {
                              var aa = a.DateString.split('-').reverse().join('-').split('-').reverse().join(),
                                bb = b.DateString.split('-').reverse().join('-').split('-').reverse().join();
                              return aa < bb ? 1 : aa > bb ? -1 : 0;
                            });
                          }
                        } else if (primarySort === 'number') {
                          if (isSortedDescendingNumber) {
                            invoices.sort((a, b) => {
                              if (b.InvoiceNumber < a.InvoiceNumber) {
                                return -1;
                              }
                              if (b.InvoiceNumber > a.InvoiceNumber) {
                                return 1;
                              }
                              return 0;
                            });
                          } else {
                            invoices.sort((a, b) => {
                              if (a.InvoiceNumber < b.InvoiceNumber) {
                                return -1;
                              }
                              if (a.InvoiceNumber > b.InvoiceNumber) {
                                return 1;
                              }
                              return 0;
                            });
                          }
                        } else if (primarySort === 'customerName') {
                          if (isSortedDescendingCustomerName) {
                            invoices.sort((a, b) => {
                              let name_a = a.Contact.Name;

                              let name_b = b.Contact.Name;

                              if (name_b < name_a) {
                                return -1;
                              }
                              if (name_b > name_a) {
                                return 1;
                              }
                              return 0;
                            });
                          } else {
                            invoices.sort((a, b) => {
                              let name_a = a.Contact.Name;

                              let name_b = b.Contact.Name;

                              if (name_a < name_b) {
                                return -1;
                              }
                              if (name_a > name_b) {
                                return 1;
                              }
                              return 0;
                            });
                          }
                        } else if (primarySort === 'vatTotal') {
                          if (isSortedDescendingVatTotal) {
                            invoices.sort(function (a, b) {
                              return b.TotalTax - a.TotalTax;
                            });
                          } else {
                            invoices.sort(function (a, b) {
                              return a.TotalTax - b.TotalTax;
                            });
                          }
                        } else if (primarySort === 'grandTotal') {
                          if (isSortedDescendingGrandTotal) {
                            invoices.sort(function (a, b) {
                              return b.Total - a.Total;
                            });
                          } else {
                            invoices.sort(function (a, b) {
                              return a.Total - b.Total;
                            });
                          }
                        }

                        const _docs = [];

                        if (invoices.length > 0) {
                          for (let i = 0; i < invoices.length; i++) {
                            let createdTime = invoices[i].DateString;

                            let dueDateTime = '';
                            if (invoices[i].DueDateString) {
                              dueDateTime = invoices[i].DueDateString;
                            }

                            let _amountTotal = Math.round(invoices[i].SubTotal * 100) / 100;
                            let _vatTotal = Math.round(invoices[i].TotalTax * 100) / 100;
                            let _grandTotal = Math.round(invoices[i].Total * 100) / 100;

                            let include_Vat = false;

                            if (invoices[i].LineAmountTypes === 'Inclusive') {
                              include_Vat = true;
                            }

                            let _email = '';
                            let _customerAddress = '';
                            let _customerPostcode = '';
                            let _customerPhone = '';
                            let _taxId = '';
                            let _taxNumberType = '';
                            let _branchNumber = '';
                            let _branchText = '';

                            let _attentionTo = '';
                            if (invoices[i].Contact !== undefined && invoices[i].Contact.EmailAddress) {
                              _email = invoices[i].Contact.EmailAddress;
                            }

                            if (invoices[i].Contact.Addresses !== undefined && invoices[i].Contact.Addresses[0] !== undefined) {
                              invoices[i].Contact.Addresses.forEach((address) => {
                                if (address.AddressType === 'POBOX' && address.AddressLine1) {
                                  _customerAddress =
                                    address.AddressLine1 +
                                    (address.AddressLine2 ? ' ' + address.AddressLine2 : '') +
                                    (address.AddressLine3 ? ' ' + address.AddressLine3 : '') +
                                    (address.AddressLine4 ? ' ' + address.AddressLine4 : '') +
                                    (address.City ? ' ' + address.City : '') +
                                    (address.Region ? ' ' + address.Region : '') +
                                    (address.Country ? ' ' + address.Country : '')
                                  if (address.PostalCode) {
                                    if (isNaN(address.PostalCode) || address.PostalCode.length !== 5) {
                                      _customerPostcode = '00000';
                                      _customerAddress = _customerAddress + ' ' + address.PostalCode;
                                    } else {
                                      _customerPostcode = address.PostalCode;
                                    }
                                  } else {
                                    let findPostcode = _customerAddress.match(/\d+/g) || []

                                    findPostcode.forEach((number) => {
                                      if (number.length === 5 && (!address.PostalCode || address.PostalCode === '')) {
                                        _customerPostcode = number;
                                        _customerAddress = _customerAddress.replace(number, '');
                                      }
                                    });
                                  }

                                  if (address.AttentionTo !== '') {
                                    _attentionTo = address.AttentionTo;
                                  }
                                }
                              });
                            }

                            if (invoices[i].Contact != null && (invoices[i].Contact.TaxNumber || invoices[i].Contact.CompanyNumber)) {
                              if (invoices[i].Contact.CompanyNumber) {
                                if (
                                  /\s/g.test(invoices[i].Contact.CompanyNumber) ||
                                  isNaN(invoices[i].Contact.CompanyNumber) ||
                                  invoices[i].Contact.CompanyNumber.length !== 13
                                ) {
                                  _taxId = invoices[i].Contact.CompanyNumber;
                                  _taxNumberType = 'CCPT';
                                } else {
                                  _taxId = invoices[i].Contact.CompanyNumber;
                                  _taxNumberType = 'TXID';
                                  _branchNumber = '00000';
                                  _branchText = 'สำนักงานใหญ่';
                                }
                              } else if (invoices[i].Contact.TaxNumber) {
                                if (
                                  /\s/g.test(invoices[i].Contact.TaxNumber) ||
                                  isNaN(invoices[i].Contact.TaxNumber) ||
                                  invoices[i].Contact.TaxNumber.length !== 13
                                ) {
                                  _taxId = invoices[i].Contact.TaxNumber;
                                  _taxNumberType = 'CCPT';
                                } else if (
                                  invoices[i].Contact.Name.includes('บริษัท') ||
                                  invoices[i].Contact.Name.includes('ห้างหุ้นส่วน') ||
                                  invoices[i].Contact.Name.includes('Company') ||
                                  invoices[i].Contact.Name.includes('company') ||
                                  invoices[i].Contact.Name.includes('ltd.') ||
                                  invoices[i].Contact.Name.includes('Ltd.') ||
                                  invoices[i].Contact.Name.includes('Limited') ||
                                  invoices[i].Contact.Name.includes('limited') ||
                                  _customerAddress.includes('สำนักงานใหญ่') ||
                                  /Head Office/i.test(_customerAddress) ||
                                  _customerAddress.includes('สาขา') ||
                                  /Branch/i.test(_customerAddress) ||
                                  _attentionTo === 'Head Office' ||
                                  _attentionTo === 'สำนักงานใหญ่'
                                ) {
                                  _taxId = invoices[i].Contact.TaxNumber;
                                  _taxNumberType = 'TXID';

                                  if (
                                    _customerAddress.includes('สำนักงานใหญ่') ||
                                    /Head Office/i.test(_customerAddress) ||
                                    _attentionTo === 'Head Office' ||
                                    _attentionTo === 'สำนักงานใหญ่'
                                  ) {
                                    _branchNumber = '00000';

                                    if (/Head Office/i.test(_customerAddress) || _attentionTo === 'Head Office') {
                                      _branchText = 'Head Office';
                                    } else if (_customerAddress.includes('สำนักงานใหญ่') || _attentionTo === 'สำนักงานใหญ่') {
                                      _branchText = 'สำนักงานใหญ่';
                                    }
                                  } else if (_customerAddress.includes('สาขา') || /Branch/i.test(_customerAddress)) {
                                    _branchNumber = '00000';

                                    if (/Branch/i.test(_customerAddress)) {
                                      _branchText = 'Branch 00000';
                                    } else {
                                      _branchText = 'สาขา 00000';
                                    }
                                  } else {
                                    _branchNumber = '00000';
                                    _branchText = 'Head Office';
                                  }
                                } else {
                                  _taxId = invoices[i].Contact.TaxNumber;
                                  _taxNumberType = 'NIDN';
                                }
                              }
                            } else {
                              _taxId = 'N/A';
                              _taxNumberType = 'OTHR';
                            }

                            if (invoices[i].Contact.Phones !== undefined && invoices[i].Contact.Phones[0] !== undefined) {
                              invoices[i].Contact.Phones.forEach((phone) => {
                                if (phone.PhoneType === 'DEFAULT' && phone.PhoneNumber) {
                                  if (phone.PhoneNumber.substring(0, 3) === '+66' || phone.PhoneNumber.substring(0, 2) === '66') {
                                    let phoneNumber = '';
                                    if (phone.PhoneNumber.substring(0, 3) === '+66') {
                                      phoneNumber = phone.PhoneNumber.replace('+66', '0');
                                    } else {
                                      phoneNumber = phone.PhoneNumber.replace('66', '0');
                                    }

                                    _customerPhone = phoneNumber;
                                  } else {
                                    _customerPhone = phone.PhoneNumber;
                                  }
                                }
                              });
                            }

                            _docs.push({
                              key: invoices[i].InvoiceID,
                              createdTime: createdTime,
                              dueDateTime: dueDateTime,
                              ContactID: invoices[i].Contact.ContactID,
                              number: invoices[i].InvoiceNumber,
                              customerName: invoices[i].Contact.Name,
                              PaymentID: invoices[i].Payments[0] ? invoices[i].Payments[0].PaymentID : '',
                              amountTotal: _amountTotal,
                              vatTotal: _vatTotal,
                              grandTotal: _grandTotal,
                              items: invoices[i].LineItems,
                              customerAddress: _customerAddress || '-',
                              customerPhone: _customerPhone,
                              customerEmail: _email,
                              customerPostcode: _customerPostcode !== '' ? _customerPostcode : '00000',
                              customerTaxId: _taxId,
                              branchNumber: _branchNumber,
                              branchText: _branchText,
                              taxNumberType: _taxNumberType,
                              note: '',
                              includeVat: include_Vat,
                              etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoices[i].InvoiceNumber),
                            });
                          }
                        }

                        setItems(_docs);

                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    setItems([]);

                    setLoadDataComplete(true);
                    setGetNewDocuments(false);
                    setIsCreateDocumentComplete(false);
                  }
                });
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        };

        searchInvoices();
      } else {
        const listInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              let url = API_URL + '/sources/xero/invoices';

              let params = {
                tenantId: tenant.key,
                page: currentPage,
                from: from2,
                to: to2,
              };

              if (documentType.key !== 'all') {
                params.status = documentType.key;
              }

              const [response] = await Promise.all([
                axios
                  .get(url, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                    params: params,
                  })
                  .catch((error) => console.log(error)),
              ]);

              console.log(response);

              let invoices = merge(response.data.invoice.filter(_invoice => _invoice.DateString), response.data.contact);

              invoices.sort((a, b) => new Date(a.DateString).getTime() - new Date(b.DateString).getTime());
              invoices.reverse();

              if (primarySort === 'documentDate') {
                if (isSortedDescendingDocumentDate) {
                  invoices.sort(function (a, b) {
                    var aa = a.DateString.split('-').reverse().join('-').split('-').reverse().join(),
                      bb = b.DateString.split('-').reverse().join('-').split('-').reverse().join();
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                } else {
                  invoices.sort(function (a, b) {
                    var aa = a.DateString.split('-').reverse().join('-').split('-').reverse().join(),
                      bb = b.DateString.split('-').reverse().join('-').split('-').reverse().join();
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  invoices.sort((a, b) => {
                    if (b.InvoiceNumber < a.InvoiceNumber) {
                      return -1;
                    }
                    if (b.InvoiceNumber > a.InvoiceNumber) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoices.sort((a, b) => {
                    if (a.InvoiceNumber < b.InvoiceNumber) {
                      return -1;
                    }
                    if (a.InvoiceNumber > b.InvoiceNumber) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  invoices.sort((a, b) => {
                    let name_a = a.Contact.Name;

                    let name_b = b.Contact.Name;

                    if (name_b < name_a) {
                      return -1;
                    }
                    if (name_b > name_a) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoices.sort((a, b) => {
                    let name_a = a.Contact.Name;

                    let name_b = b.Contact.Name;

                    if (name_a < name_b) {
                      return -1;
                    }
                    if (name_a > name_b) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'vatTotal') {
                if (isSortedDescendingVatTotal) {
                  invoices.sort(function (a, b) {
                    return b.TotalTax - a.TotalTax;
                  });
                } else {
                  invoices.sort(function (a, b) {
                    return a.TotalTax - b.TotalTax;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  invoices.sort(function (a, b) {
                    return b.Total - a.Total;
                  });
                } else {
                  invoices.sort(function (a, b) {
                    return a.Total - b.Total;
                  });
                }
              }

              const _docs = [];

              if (invoices.length > 0) {
                const _numberList = invoices.map(invoices => {
                  return String(invoices.InvoiceNumber)
                }).join(",")

                const documentsResponse = await axios.get(`${API_URL}/documents/?numberList=${_numberList}`, {
                  headers: {
                      Authorization: "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                  },
                })
                .catch((error) => console.log(error))


                for (let i = 0; i < invoices.length; i++) {
                  let createdTime = invoices[i].DateString;

                  let dueDateTime = '';
                  if (invoices[i].DueDateString) {
                    dueDateTime = invoices[i].DueDateString;
                  }

                  let _amountTotal = Math.round(invoices[i].SubTotal * 100) / 100;
                  let _vatTotal = Math.round(invoices[i].TotalTax * 100) / 100;
                  let _grandTotal = Math.round(invoices[i].Total * 100) / 100;

                  let include_Vat = false;

                  if (invoices[i].LineAmountTypes === 'Inclusive') {
                    include_Vat = true;
                  }

                  let _email = '';
                  let _customerAddress = '';
                  let _customerPostcode = '';
                  let _customerPhone = '';
                  let _taxId = '';
                  let _taxNumberType = '';
                  let _branchNumber = '';
                  let _branchText = '';

                  let _attentionTo = '';
                  if (invoices[i].Contact !== undefined && invoices[i].Contact.EmailAddress) {
                    _email = invoices[i].Contact.EmailAddress;
                  }

                  if (invoices[i].Contact.Addresses !== undefined && invoices[i].Contact.Addresses[0] !== undefined) {
                    invoices[i].Contact.Addresses.forEach((address) => {
                      if (address.AddressType === 'POBOX' && address.AddressLine1) {
                        _customerAddress =
                          address.AddressLine1 +
                          (address.AddressLine2 ? ' ' + address.AddressLine2 : '') +
                          (address.AddressLine3 ? ' ' + address.AddressLine3 : '') +
                          (address.AddressLine4 ? ' ' + address.AddressLine4 : '') +
                          (address.City ? ' ' + address.City : '') +
                          (address.Region ? ' ' + address.Region : '') +
                          (address.Country ? ' ' + address.Country : '')
                        if (address.PostalCode) {
                          if (isNaN(address.PostalCode) || address.PostalCode.length !== 5) {
                            _customerPostcode = '00000';
                            _customerAddress = _customerAddress + ' ' + address.PostalCode;
                          } else {
                            _customerPostcode = address.PostalCode;
                          }
                        } else {
                          let findPostcode = _customerAddress.match(/\d+/g) || []

                          findPostcode.forEach((number) => {
                            if (number.length === 5 && (!address.PostalCode || address.PostalCode === '')) {
                              _customerPostcode = number;
                              _customerAddress = _customerAddress.replace(number, '');
                            }
                          });
                        }

                        if (address.AttentionTo !== '') {
                          _attentionTo = address.AttentionTo;
                        }
                      }
                    });
                  }

                  if (invoices[i].Contact != null && (invoices[i].Contact.TaxNumber || invoices[i].Contact.CompanyNumber)) {
                    if (invoices[i].Contact.CompanyNumber) {
                      if (
                        /\s/g.test(invoices[i].Contact.CompanyNumber) ||
                        isNaN(invoices[i].Contact.CompanyNumber) ||
                        invoices[i].Contact.CompanyNumber.length !== 13
                      ) {
                        _taxId = invoices[i].Contact.CompanyNumber;
                        _taxNumberType = 'CCPT';
                      } else {
                        _taxId = invoices[i].Contact.CompanyNumber;
                        _taxNumberType = 'TXID';
                        _branchNumber = '00000';
                        _branchText = 'สำนักงานใหญ่';
                      }
                    } else if (invoices[i].Contact.TaxNumber) {
                      if (
                        /\s/g.test(invoices[i].Contact.TaxNumber) ||
                        isNaN(invoices[i].Contact.TaxNumber) ||
                        invoices[i].Contact.TaxNumber.length !== 13
                      ) {
                        _taxId = invoices[i].Contact.TaxNumber;
                        _taxNumberType = 'CCPT';
                      } else if (
                        invoices[i].Contact.Name.includes('บริษัท') ||
                        invoices[i].Contact.Name.includes('ห้างหุ้นส่วน') ||
                        invoices[i].Contact.Name.includes('Company') ||
                        invoices[i].Contact.Name.includes('company') ||
                        invoices[i].Contact.Name.includes('ltd.') ||
                        invoices[i].Contact.Name.includes('Ltd.') ||
                        invoices[i].Contact.Name.includes('Limited') ||
                        invoices[i].Contact.Name.includes('limited') ||
                        _customerAddress.includes('สำนักงานใหญ่') ||
                        /Head Office/i.test(_customerAddress) ||
                        _customerAddress.includes('สาขา') ||
                        /Branch/i.test(_customerAddress) ||
                        _attentionTo === 'Head Office' ||
                        _attentionTo === 'สำนักงานใหญ่'
                      ) {
                        _taxId = invoices[i].Contact.TaxNumber;
                        _taxNumberType = 'TXID';

                        if (
                          _customerAddress.includes('สำนักงานใหญ่') ||
                          /Head Office/i.test(_customerAddress) ||
                          _attentionTo === 'Head Office' ||
                          _attentionTo === 'สำนักงานใหญ่'
                        ) {
                          _branchNumber = '00000';

                          if (/Head Office/i.test(_customerAddress) || _attentionTo === 'Head Office') {
                            _branchText = 'Head Office';
                          } else if (_customerAddress.includes('สำนักงานใหญ่') || _attentionTo === 'สำนักงานใหญ่') {
                            _branchText = 'สำนักงานใหญ่';
                          }
                        } else if (_customerAddress.includes('สาขา') || /Branch/i.test(_customerAddress)) {
                          _branchNumber = '00000';

                          if (/Branch/i.test(_customerAddress)) {
                            _branchText = 'Branch 00000';
                          } else {
                            _branchText = 'สาขา 00000';
                          }
                        } else {
                          _branchNumber = '00000';
                          _branchText = 'Head Office';
                        }
                      } else {
                        _taxId = invoices[i].Contact.TaxNumber;
                        _taxNumberType = 'NIDN';
                      }
                    }
                  } else {
                    _taxId = 'N/A';
                    _taxNumberType = 'OTHR';
                  }

                  if (invoices[i].Contact.Phones !== undefined && invoices[i].Contact.Phones[0] !== undefined) {
                    invoices[i].Contact.Phones.forEach((phone) => {
                      if (phone.PhoneType === 'DEFAULT' && phone.PhoneNumber) {
                        if (phone.PhoneNumber.substring(0, 3) === '+66' || phone.PhoneNumber.substring(0, 2) === '66') {
                          let phoneNumber = '';
                          if (phone.PhoneNumber.substring(0, 3) === '+66') {
                            phoneNumber = phone.PhoneNumber.replace('+66', '0');
                          } else {
                            phoneNumber = phone.PhoneNumber.replace('66', '0');
                          }

                          _customerPhone = phoneNumber;
                        } else {
                          _customerPhone = phone.PhoneNumber;
                        }
                      }
                    });
                  }

                  _docs.push({
                    key: invoices[i].InvoiceID,
                    createdTime: createdTime,
                    dueDateTime: dueDateTime,
                    ContactID: invoices[i].Contact.ContactID,
                    number: invoices[i].InvoiceNumber,
                    customerName: invoices[i].Contact.Name,
                    PaymentID: invoices[i].Payments[0] ? invoices[i].Payments[0].PaymentID : '',
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    items: invoices[i].LineItems,
                    customerAddress: _customerAddress || '-',
                    customerPhone: _customerPhone,
                    customerEmail: _email,
                    customerPostcode: _customerPostcode !== '' ? _customerPostcode : '00000',
                    customerTaxId: _taxId,
                    branchNumber: _branchNumber,
                    branchText: _branchText,
                    taxNumberType: _taxNumberType,
                    note: '',
                    includeVat: include_Vat,
                    // etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoices[i].InvoiceNumber),
                    etaxCreated: invoices[i].InvoiceNumber ? documentsResponse.data.some(_doc => _doc.data.number === String(invoices[i].InvoiceNumber)) : false,
                  });
                }
              }

              console.log("_docs", _docs)
              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              //   instance.acquireTokenRedirect(silentRequest);
              // disconnect(true);
            });
        };

        listInvoices();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments, tenant]);

  useEffect(() => {
    setLoadDataComplete(false);

    setItems([]);
    setGetNewDocuments(true);

    sessionStorage.setItem('xero-currentPage', currentPage);
  }, [currentPage]);

  useEffect(() => {
    console.log("isCreateDocumentComplete useEffect: ", isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
        let _items = selection.getItems();
        console.log("_items: ", _items);

        let _selectedItems = selection.getSelection();

        if (isCreateDocumentComplete) {
            setIsCreateDocumentComplete(false);

            for (let i = 0; i < _items.length; i++) {
                if (_items[i].status !== "") {
                    _items[i].status = "";
                }
            }
        } else {
            for (let i = 0; i < _items.length; i++) {
                if (_items[i].status !== "") {
                    _items[i].status = "";
                }
            }

            for (let i = 0; i < _selectedItems.length; i++) {
                console.log("select item key: ", _selectedItems[i].key);

                for (let j = 0; j < _items.length; j++) {
                    if (_items[j].key === _selectedItems[i].key) {
                        console.log("set pending item key: ", _items[j].key);
                        _items[j].status = "pending";
                        console.log("_items: ", _items);
                    }
                }
            }
        }

        setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  return (
    <div style={homeStyles}>
      {!isLoading ? (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('xero.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('xero.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }} styles={{ root: { marginTop: 10 } }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('xero.createdBy')}
                  description={t('xero.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('xero.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('xero.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE');
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('xero.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('xero.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT');
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('xero.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('INVOICE-TAXINVOICE');
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('xero.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('xero.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('TAXINVOICE');
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('xero.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('xero.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('xero.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}

          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '800px',
                    },
                  }}
                >
                  <CommandBar items={commandBarItems} overflowItems={overflowItems} />
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        width: 200,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete || isCreateDocument}
                    placeholder={t('xero.searchBoxPlaceholder')}
                    onChange={(e) => setSearch(e ? e.target.value : '')}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || isCreateDocument || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 8,
                        width: 90,
                      },
                    }}
                    text={t('xero.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              {items.length && !checkItems(items) ? (
                <ShimmeredDetailsList
                  items={items || []}
                  enableShimmer={!loadDataComplete}
                  compact={isCompactMode}
                  columns={columns}
                  selection={selection}
                  selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  checkboxVisibility={CheckboxVisibility.always}
                  selectionPreservedOnEmptyClick={true}
                />
              ) : (
                <ShimmeredDetailsList
                  items={[{}]}
                  enableShimmer={!loadDataComplete}
                  compact={isCompactMode}
                  columns={columns}
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  selection={selection}
                  selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                  checkboxVisibility={CheckboxVisibility.always}
                  selectionPreservedOnEmptyClick={true}
                  onRenderRow={(props) => {
                    if (props) {
                        if (((Object.keys(props.item).length === 1 && Object.keys(props.item).includes("status")) || Object.keys(props.item).length === 0) && Object.getPrototypeOf(props.item) === Object.prototype) {
                            return (
                                <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: "#FFFFFF", height: 55 } }}>
                                    <Text>{t("documents.noItemFound")}</Text>
                                </Stack>
                            );
                        } else {
                            return <DetailsRow {...props} />;
                        }
                    }
                  }}
                />
              )
              }
            </Stack>
          </Stack>
          <br />
          <center>
            {(!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && items.length && !checkItems(items)) ? (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageDropdown.length}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                previousPageIconProps={{ iconName: 'ChevronLeft' }}
                nextPageIconProps={{ iconName: 'ChevronRight' }}
                lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
              />
            ) : null}

            <br />
            <br />
          </center>
        </div>
      ) : (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('xero.loading')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default Xero;
